var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "accordion-item" }, [
    _c("div", { staticClass: "accordion-header" }, [
      _c(
        "button",
        {
          staticClass: "accordion-btn",
          class: {
            collapsed: !_vm.active,
          },
          attrs: { disabled: _vm.disabled },
          on: {
            click: function ($event) {
              return _vm.toggle($event)
            },
          },
        },
        [_vm._v("\n            " + _vm._s(_vm.header) + "\n        ")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "accordion-body collapse", class: { show: _vm.active } },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }